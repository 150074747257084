import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartFacade } from '@ev-portals/cp/frontend/purchase/data-access';
import {
  AuthenticationService,
  FederationService,
  SelectedLocationService,
} from '@ev-portals/cp/frontend/shared/auth/util';
import { AnalyticsService } from '@ev-portals/cp/frontend/shared/util';

import { OriginService } from './util/user-preference/origin.service';
import { ThemeService } from './util/user-preference/theme.service';

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'cp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ThemeService, OriginService],
})
export class AppComponent {
  // Init analytics service and authService
  private analyticsServie = inject(AnalyticsService);
  private authService = inject(AuthenticationService);
  // Necessary to trigger the address selection modal
  private locationSelectionService = inject(SelectedLocationService);
  // Needs to be injected to keep cart in sync (other domain may add items to the cart)
  private cartFacade = inject(CartFacade);
  // Inject to handle Single-Sign-On
  private federationService = inject(FederationService);
  // Handle user preferences (theme, origin, etc.)
  private themeService = inject(ThemeService);
  private originService = inject(OriginService);
}
