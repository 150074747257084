{
  "dirty": true,
  "raw": "v1.71.0-1-gb7f53eb5d-dirty",
  "hash": "gb7f53eb5d",
  "distance": 1,
  "tag": "v1.71.0",
  "semver": {
    "options": {
      "loose": false,
      "includePrerelease": false
    },
    "loose": false,
    "raw": "v1.71.0",
    "major": 1,
    "minor": 71,
    "patch": 0,
    "prerelease": [],
    "build": [],
    "version": "1.71.0"
  },
  "suffix": "1-gb7f53eb5d-dirty",
  "semverString": "1.71.0+1.gb7f53eb5d.dirty"
}