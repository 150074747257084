<div class="flex flex-col text-white">
  <span class="text-xl" i18n>Welcome to our myPerformanceChemicals</span>
  <span class="text" i18n>
    Here you will find comprehensive information and details about our product
    portfolio.
  </span>
  @if (user$ | async; as user) {
    <p-button
      class="mt-4"
      (onClick)="navigateToProductsPage()"
      [label]="exploreProductsLabel"></p-button>
  } @else {
    <p-button
      class="mt-4"
      data-cy="landing-login"
      (onClick)="navigateToLoginPage()"
      [label]="loginLabel"></p-button>
  }
</div>
